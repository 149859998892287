.create-button-display {
 text-transform: inherit!important;
}
.curation-link {
 color: #0000ff;
 text-decoration: none;
}
table.custom-table thead th:nth-child(2) {
    min-width:250px!important;
}
table.custom-table thead th:nth-child(1) {
    min-width:5px!important;
}
.svg_icons{
    transform: scale(0.8);
}
.dashboard-head{
    min-width: 200px!important;
}
.header-curation{
    background-color: rgb(244, 247, 247);
}
.curation-list-flex{
    flex: 1!important;
    flex-wrap: wrap;
    padding: 15px;
    /* margin-right: 10px; */
    /* margin-bottom: 10px; */
    text-align: center;
}
.curation-card-flex{
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
}
.card-label{
    padding-right: 15px;
    padding-bottom: 15px;
    text-align: left;
}
.card-count{
    text-align: right;
    font-weight: 1000;
    font-size: x-large;
    padding-top: 10px;
}
.svg_icons-curate-count{
    font-size: 40px!important;
    background: #68b828;
    color: #fff;
    text-align: center;
    line-height: 50px;
    width: 50px!important;
    height: 50px!important;
    border-radius: 50%;
    display: inline-block;
}
.svg_icons-curate-count.icon-bg2{
    background: #7c38bc!important;
}
.svg_icons-curate-count.icon-bg3{
    background: #40bbea!important;
}
.article-count-icon{
   
    width: 30px!important;
    height: 30px!important;
}
.card-title{
    margin-top: 5px!important;
    margin-bottom: 10px!important;
}
.article-count{
    font-size: 35px;
    font-weight: bold;
}
.article-count-title{
    font-size: 12px;
    text-transform: uppercase;
    color: #979898;
    margin-top: 15px;
    margin-bottom: 5px;
}