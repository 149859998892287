.check-box {
  padding-left: 4%;
}

.curate-url {
  width: 1000px;
}

.curation-card {
  min-height: 800px;
  width: 100%;
}

.edit-curation-card {
  min-height: 800px;
  width: 950px;
  width: 90%;
}

.form-textfield {
  width: 100%;
}

.curate-pull-right {
  float: right;
}

.coe-validation-error {
  padding-top: 5px;
  color: red;
}

.button-save {
  /* float: right; */
  padding-left: 60%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.header-button {
  padding-left: 4%;
}

.delete-button {
  padding-left: 2%;
}

.ogImgSelected {
  border: 4px solid #3f51b5 !important;
  background: #ededed;
}

.curation-images-outer {
  float: left;
  border: 1px solid #949494;
  height: 120px;
  width: 120px;
  margin: 3px 3px;
  align-items: center;
  justify-content: center;
  display: flex;
  box-sizing: border-box;
}

.curation-images {
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 100%;
  cursor: pointer;
  text-align: center;
  height: inherit;
  /* padding: 5px 5px; */
}

.press-release-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .ck.ck-reset.ck-editor {
   
    min-height: 100px;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
} */
/* .ck.ck-editor__main{   
    min-height: 100px;
    max-height: 350px;
    overflow: auto;
} */
.ck.ck-content.ck-editor__editable {
  min-height: 100px;
  max-height: 350px;
  overflow: auto;
}

.curation-content {
  padding: 2%;
}

.curation-validation-error {
  color: red;
}

.curated-data-hidden {
  display: none;
}

.coe-hidden {
  display: none;
}

.curated-data-hide {
  opacity: 0.50;
  top: 0px;
  /* position: absolute;*/
  display: block;
  width: 100%;
}

.curated-data .hidden {
  display: none;
}

.header-card{
  padding: 0px !important; 
}
.curation-image {
  float: inherit !important
}
.button-delete{
  text-align: center;
  margin-top: 10px;
  width: 120px;
}

.align-right{
  float:right;
}
.curate-cancel-right{
  float:left;
}
.cancel-button{
  padding: 3%;
}
.inner-card{
  color: #fff;
  font-weight: 300;
  border: 1px solid #fff;
  padding: 8px 14px;
  background: rgba(155, 155, 155, 0.2);
}
.article-view-badge{
  background-color: #fafaf9;
  padding: 15px 20px;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
}
.clear{
  clear: both;
}
.curation-image-box{
  width: 118px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
  
}
.curation-image-title{
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #555;
  background: #fff;
  border: 1px solid #ddd8d8;
  border-top: 0px;
  padding-bottom: 7px;
  float: left;
  margin-left: 3px;
  margin-top: -3px;
  padding-top:3px;
}
.article-scroll-badge{
  height: 200px;
  overflow-y: scroll;
  background-color: #fafaf9;
  padding: 15px 20px;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
}
.curate-pull-left{
  padding-left: 2%;
}
.profile-update{
  padding: 30px;
}
.error-message{
  color: red;
}
.img-profile{
   
    width: 180px!important;
    height: 180px!important;
}
.pocn-text{
  margin-right: 50px;
}
.pocn-required{
  color: red;
}
.input-text-pocn{
  height: 40px;
  width: 300px;
}
.preferences-box{
  outline-width: thin;
  outline-color: rgb(221, 220, 220) !important;
  outline-style: auto;
}
.list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}
.list-group-item {
    position: relative;
    display: block;
    padding: 8px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
}
.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.list-separation{
  color: #ccc;
}
.select-year{
  width: 695px;
  height: 45px;
}
.section-add-icon{
    color: black;
}
.section-add-button{
  float: right;
}
.select-radio{
  margin-right: 20px;
}